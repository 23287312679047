<template>
  <v-dialog v-model="sectionDialog" persistent max-width="600px">
    <v-card>
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline"
          >{{ section._id == -1 ? "Add" : "Edit" }} Section</span
        >
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="item.label"
                label="Label*"
                type="name"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="item.key" label="Key" type="name" disabled>
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="item.order"
                label="Order"
                type="name"
                @input="onChange"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text class="pt-0 pl-9">
        <small>*indicates required field</small>
      </v-card-text>
      <v-alert dense type="error" v-if="error" class="mx-4">
        {{ error }}
      </v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
          {{ section._id == -1 ? "Add" : "Edit" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    sectionDialog: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => {
        return {
          _id: -1,
          label: "",
          key: "",
        };
      },
    },
    onCloseSectionDialog: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      statusOptions: ["Yes", "No", "Urgent Help", "No response"],
      error: null,
      loading: false,
      item: {
        label: "",
        key: "",
      },
    };
  },
  methods: {
    ...mapActions("question", {
      addSection: "addSection",
      editQuestion: "editQuestion",
    }),

    onSave() {
      this.loading = true;
      if (this.section._id == -1) {
        this.addSection({ ...this.item })
          .then(() => {
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.error = error;
          });
      } else {
        this.editQuestion({ ...this.item })
          .then(() => {
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.error = error;
          });
      }
    },
    onClose() {
      this.onCloseSectionDialog();
    },
    onChange() {
      this.error = null;
      this.section.key = this.section.label.replace(/[^A-Z0-9]+/gi, "_");
      this.section.key = this.section.key.toLowerCase();
    },
  },
  watch: {
    section(newValue) {
      this.item = newValue;
    },
  },
  mounted() {
    this.item = { ...this.section };
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
