<template>
  <v-dialog v-model="textInputDialog" persistent max-width="600px">
    <v-card>
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline"
          >{{ item._id == -1 ? "Add" : "Edit" }} Form Element</span
        >
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <v-container>
          <v-row>
            <v-icon color="blue">mdi-radiobox-marked</v-icon>
            <div style="color:blue; padding-left: 10px;">Radio Input:</div>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="item.label"
                label="Label"
                type="name"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="item.key" label="Key" type="name" disabled>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.short_label"
                label="Short label"
                type="name"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.placeholder"
                label="Placeholder"
                type="name"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="item.col"
                label="Column"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="item.order"
                label="Order"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="item.intake"
                label="Init Intake Index"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-alert dense type="error" v-if="error" class="mx-4">
        {{ error }}
      </v-alert>
      <v-card-actions>
        <tool-tip-button
          icon="mdi-arrow-left"
          :clicked="onBack"
          tooltips="Select another element"
        />
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
          {{ item._id == -1 ? "Add" : "Save" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ToolTipButton from "../core/ToolTipButton.vue";

export default {
  components: { ToolTipButton },
  props: {
    textInputDialog: {
      type: Boolean,
      default: false,
    },
    onCloseTextInputDialog: {
      type: Function,
    },
    onBack: Function,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      statusOptions: ["Yes", "No", "Urgent Help", "No response"],
      error: null,
      loading: false,
      item: {
        label: "",
        key: "",
      },
    };
  },
  methods: {
    ...mapActions("question", {
      addQuestion: "addQuestion",
      editQuestion: "editQuestion",
    }),

    onSave() {
      this.loading = true;
      if (this.item._id == -1) {
        this.addQuestion({ ...this.item, form_field: "Radio Input" })
          .then(() => {
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.error = error;
          });
      } else {
        this.editQuestion({ ...this.item })
          .then(() => {
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.error = error;
          });
      }
    },
    onClose() {
      this.onCloseTextInputDialog();
    },
    onChange() {
      this.error = null;
      this.item.key = this.item.label.replace(/[^A-Z0-9]+/gi, "_");
      this.item.key = this.item.key.toLowerCase();
    },
    onSelectedType() {},
  },
  computed: {
    ...mapGetters({ question: "question/getSelectedQuestion" }),
  },
  watch: {
    question(newValue) {
      if (newValue) {
        this.item = newValue;
      } else {
        this.item = { _id: -1 };
      }
    },
  },
  mounted() {
    this.item = this.question;
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
