<template>
  <div>
    <text-input-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Text Input'"
    />
    <text-area-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Textarea'"
    />
    <select-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Select'"
    />

    <radio-input-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Radio Input'"
    />

    <checkbox-input-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Checkbox Input'"
    />
    <toggle-input-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Toggle Input'"
    />
    <file-input-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'File Input'"
    />
    <repeater-format
      :textInputDialog="inputDialog"
      :onCloseTextInputDialog="onCloseInputDialog"
      :onBack="onBackFromFormat"
      v-if="question.form_field == 'Repeater Element'"
    />
  </div>
</template>
<script>
import CheckboxInputFormat from "./CheckboxInputFormat.vue";
import RadioInputFormat from "./RadioInputFormat.vue";
import RepeaterFormat from "./RepeaterFormat.vue";
import SelectFormat from "./SelectFormat.vue";
import TextAreaFormat from "./TextAreaFormat.vue";
import TextInputFormat from "./TextInputFormat.vue";
import ToggleInputFormat from "./ToggleInputFormat.vue";
import { mapGetters } from "vuex";
import FileInputFormat from "./FileInputFormat.vue";
export default {
  components: {
    TextInputFormat,
    TextAreaFormat,
    SelectFormat,
    RadioInputFormat,
    ToggleInputFormat,
    RepeaterFormat,
    CheckboxInputFormat,
    FileInputFormat,
  },
  props: {
    inputDialog: Boolean,
    onBackFromFormat: Function,
    onCloseInputDialog: Function,
  },
  computed: {
    ...mapGetters({ question: "question/getSelectedQuestion" }),
  },
};
</script>
