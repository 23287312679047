<template>
  <v-dialog v-model="newFormTypeDialog" persistent max-width="850px">
    <v-card>
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">New Form Type</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="pb-5">
          <v-col cols="3" v-for="(formItem, key) in formTypes" :key="key">
            <v-card @click="onSelected(formItem)">
              <div class="form-item">
                <v-icon size="50" class="mt-5 mb-2">{{ formItem.icon }}</v-icon>
                <div class="mb-3 form-item-text">{{ formItem.text }}</div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    newFormTypeDialog: {
      type: Boolean,
      default: false,
    },
    onSelected: Function,
    onCloseDialog: {
      type: Function,
    },
  },
  data() {
    return {
      formTypes: [
        { text: "Text Input", icon: "mdi-format-text" },
        { text: "Textarea", icon: "mdi-message-text" },
        { text: "Select", icon: "mdi-form-select" },
        { text: "Radio Input", icon: "mdi-radiobox-marked" },
        { text: "Checkbox Input", icon: "mdi-checkbox-marked" },
        { text: "Toggle Input", icon: "mdi-toggle-switch" },
        { text: "File Input", icon: "mdi-file-image" },
        { text: "Repeater Element", icon: "mdi-repeat" },
      ],
    };
  },
  methods: {
    onClose() {
      this.onCloseDialog();
    },
  },
};
</script>
<style scoped>
.form-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-item:hover {
  background-color: yellowgreen;
}
.form-item-text {
  font-family: "Poppins-Bold";
  font-size: 12px;
}
</style>
