<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="clicked" v-if="clicked"
          ><v-icon>{{ icon }}</v-icon></v-btn
        >
      </template>
      <span>{{ tooltips }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    tooltips: {
      type: String,
      default: "",
    },
    icon: String,
    clicked: {
      type: Function,
      // default: () => {
      //   console.log("tip clicked");
      // },
    },
  },
};
</script>
