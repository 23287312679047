<template>
  <v-card
    class="ma-3 pb-1"
    :style="expanded ? 'background-color:#edf2f7;' : 'background-color:white'"
  >
    <v-card-text class="mx-5 py-6">
      <v-row style="display:flex; align-items:center">
        <v-icon class="mr-3 mt-1">{{ questionIcon }}</v-icon>
        <div
          style="max-width: calc(100% - 168px); display: flex; align-items: center;"
        >
          <div class="intake-item-title mr-4">{{ question.label }}</div>
          <div class="item-red-title mr-4" v-if="question.required">
            (Required)
          </div>
          <div class="item-blue-title mr-4" v-if="question.conditional">
            (Conditional)
          </div>
        </div>
        <v-spacer v-if="question.form_field !== `Section`" />
        <div style="display: flex; flex-grow: 1;justify-content: flex-end;">
          <tool-tip-button
            tooltips="Add Question"
            icon="mdi-plus"
            class="mr-3"
            :clicked="onAddNewChildQuestion"
            v-if="question.form_field === `Repeater Element`"
          />
          <tool-tip-button
            :tooltips="
              question.form_field === 'Section'
                ? 'Edit Section'
                : 'Edit Element'
            "
            icon="mdi-pencil"
            class="mr-3"
            :clicked="onEditButtonClicked"
          />
          <v-spacer v-if="question.form_field === `Section`" />
          <tool-tip-button
            tooltips="Add Element"
            icon="mdi-plus"
            class="mr-3"
            :clicked="onAddNewQuestion"
            v-if="question.form_field === `Section`"
          />
          <tool-tip-button
            :tooltips="
              question.form_field === `Section`
                ? 'Delete Section'
                : 'Delete Element'
            "
            icon="mdi-delete-forever"
            :class="question.form_field === `Section` ? 'mr-3' : 'mr-8'"
            :clicked="onDeleteButtonClicked"
          />
          <tool-tip-button
            :tooltips="expanded ? 'Collapse Section' : 'Expand Section'"
            :icon="
              expanded
                ? 'mdi-unfold-less-horizontal'
                : 'mdi-unfold-more-horizontal'
            "
            class="mr-8"
            :clicked="onToggleSection"
            v-if="question.form_field === `Section`"
          />
        </div>
      </v-row>
    </v-card-text>
    {{ globalDragging && dragging != globalDragging }}
    <draggable
      v-model="questions"
      v-bind="dragOptions"
      v-if="expanded || question.form_field != 'Section'"
      ghost-class="ghost"
      chosen-class="chosen"
      drag-class="drag"
      @start="startDragging"
      @end="endDragging"
      :disabled="globalDragging && dragging != globalDragging"
    >
      <transition-group type="transition" name="flip-list">
        <intake-question
          v-for="item in questions"
          :key="item._id"
          :question="item"
          :onEditSection="onEditQuestion"
          :onToggleExpand="onToggleExpand"
          :ref="`${item._id}`"
        />
      </transition-group>
    </draggable>
    <transition name="fade">
      <div class="rounded-lg btn-section mx-3 my-5" v-if="expanded">
        <v-btn color="green white--text" rounded @click="onAddNewQuestion"
          >Add New Question</v-btn
        >
      </div>
    </transition>
    <new-form-type
      :newFormTypeDialog="newFormTypeDialog"
      :onCloseDialog="onCloseFormTypeDialog"
      :onSelected="onSelectedFormType"
    />
    <input-form-format
      :inputDialog="inputDialog"
      :onCloseInputDialog="onCloseInputDialog"
      :onBackFromFormat="onBackFromFormat"
    />
    <confirm-delete-dialog
      :deleteDialog="deleteQuestionDialg"
      :onClose="onCloseConfirmDelete"
      :onConfirm="onConfirmDelete"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-card>
</template>
<script>
import ConfirmDeleteDialog from "../ConfirmDeleteDialog.vue";
import ToolTipButton from "../core/ToolTipButton.vue";
import NewFormType from "../form/NewFormType.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import draggable from "vuedraggable";
import IntakeQuestion from "./IntakeQuestion.vue";
import InputFormFormat from "../form/InputFormFormat.vue";
export default {
  name: "IntakeQuestion",
  components: {
    ToolTipButton,
    NewFormType,
    ConfirmDeleteDialog,
    draggable,
    IntakeQuestion,
    InputFormFormat,
  },
  props: {
    question: Object,
    onEditSection: Function,
    onToggleExpand: {
      type: Function,
      default: () => {
        console.log("toggle expand");
      },
    },
  },
  data() {
    return {
      expanded: false,
      newFormTypeDialog: false,
      deleteQuestionDialg: false,
      inputDialog: false,
      errorMessage: null,
      snackbar: false,
      questions: [],
      dragging: false,
    };
  },
  methods: {
    ...mapActions("question", {
      deleteQuestion: "deleteQuestion",
      startGlobalDragging: "startDragging",
      endGlobalDragging: "endDragging",
      selectQuestion: "selectQuestion",
    }),
    onExpand() {
      this.expanded = true;
    },
    onCollapse() {
      this.expanded = false;
    },
    onAddNewQuestion() {
      this.newFormTypeDialog = true;
      this.selectQuestion({
        _id: -1,
        key: "",
      });
      if (!this.expanded) {
        this.onToggleSection();
      }
    },
    onAddNewChildQuestion() {
      this.newFormTypeDialog = true;
      this.selectQuestion({
        _id: -1,
        key: "",
      });
    },
    onCloseFormTypeDialog() {
      this.newFormTypeDialog = false;
    },
    onCloseInputDialog() {
      this.inputDialog = false;
    },
    onEditButtonClicked() {
      this.onEditSection(this.question);
    },
    onEditQuestion(item) {
      if (item.conditional && item.condition_id) {
        item.condition_id_item = this.allQuestions.filter(
          (q) => q._id === item.condition_id
        )[0].label;
      }
      this.selectQuestion(item);
      this.inputDialog = true;
    },
    onDeleteButtonClicked() {
      this.deleteQuestionDialg = true;
    },
    onCloseConfirmDelete() {
      this.deleteQuestionDialg = false;
    },
    onConfirmDelete() {
      this.deleteQuestionDialg = false;
      this.loading = true;
      this.deleteQuestion(this.question)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    onToggleSection() {
      this.onToggleExpand(this.question);
    },
    onSelectedFormType(item) {
      this.newFormTypeDialog = false;
      this.selectQuestion({
        _id: -1,
        key: "",
        form_field: item.text,
        parent: this.question._id,
      });
      this.inputDialog = true;
    },
    onBackFromFormat() {
      this.inputDialog = false;
      this.newFormTypeDialog = true;
    },
    startDragging() {
      this.dragging = true;
      this.startGlobalDragging();
    },
    endDragging() {
      this.dragging = false;
      this.endGlobalDragging();
    },
  },
  computed: {
    ...mapGetters({ allQuestions: "question/getAllQuestions" }),
    ...mapState({ globalDragging: "question/dragging" }),
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    questionIcon() {
      switch (this.question.form_field) {
        case "Section":
          return "mdi-format-list-text";
        case "Text Input":
          return "mdi-format-text";
        case "Textarea":
          return "mdi-message-text";
        case "Select":
          return "mdi-form-select";
        case "Radio Input":
          return "mdi-radiobox-marked";
        case "Checkbox Input":
          return "mdi-checkbox-marked";
        case "Toggle Input":
          return "mdi-toggle-switch";
        case "File Input":
          return "mdi-file-image";
        case "Repeater Element":
          return "mdi-repeat";
        default:
          return "mdi-format-list-text";
      }
    },
  },
  watch: {
    allQuestions(newValue) {
      this.questions = newValue
        .filter((q) => q.parent === this.question._id)
        .sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order;
          }
          if (a.order) return -1;
          if (b.order) return 1;
          if (a._id > b._id) return 1;
          else if (a._id < b._id) return -1;
          return 0;
        });
    },
  },
  mounted() {
    this.questions = this.allQuestions
      .filter((q) => q.parent === this.question._id)
      .sort((a, b) => {
        if (a.order && b.order) {
          return a.order - b.order;
        }
        if (a.order) return -1;
        if (b.order) return 1;
        if (a._id > b._id) return 1;
        else if (a._id < b._id) return -1;
        return 0;
      });
  },
};
</script>
<style scoped>
.intake-item-title {
  color: gray;
  font-family: "Poppins-Medium";
  font-size: 18px;
}
.item-red-title {
  color: red;
  font-family: "Poppins-Medium";
  font-size: 18px;
}
.item-blue-title {
  color: blue;
  font-family: "Poppins-Medium";
  font-size: 18px;
}
.btn-section {
  height: 80px;
  border-style: dashed;
  border-color: grey;
  align-items: center;
  display: flex;
  justify-content: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.ghost {
  opacity: 0.5;
  background: red;
}
.chosen {
  opacity: 0.5;
  background: blue;
}
.drag {
  opacity: 0.5;
  background: green;
}
</style>
