<template>
  <div>
    <draggable
      v-model="sections"
      v-bind="dragOptions"
      :disabled="globalDragging && dragging != globalDragging"
      @start="startDragging"
      @end="endDragging"
    >
      <transition-group type="transition" name="flip-list">
        <intake-question
          v-for="item in sections"
          :key="item._id"
          :question="item"
          :onEditSection="onEditSection"
          :onToggleExpand="onToggleExpand"
          :ref="`${item._id}`"
        />
      </transition-group>
    </draggable>
    <div class="rounded-lg btn-section mx-3 my-5">
      <v-btn color="green white--text" @click="addSection" rounded>
        Add Section
      </v-btn>
    </div>
    <edit-section-dialog
      :sectionDialog="sectionDialog"
      :onCloseSectionDialog="onCloseSectionDialog"
      :section="selectedItem ? selectedItem : { ...defaultItem }"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import IntakeQuestion from "../intake/IntakeQuestion.vue";
import { mapActions, mapState } from "vuex";
import EditSectionDialog from "./EditSectionDialog.vue";
export default {
  display: "Edit Intake Questions",
  props: {
    questions: Array,
  },
  components: {
    draggable,
    IntakeQuestion,
    EditSectionDialog,
  },
  data() {
    return {
      loading: false,
      sectionDialog: false,
      selectedItem: null,
      defaultItem: {
        _id: -1,
        label: "",
        key: "",
      },
      dragging: false,
    };
  },
  methods: {
    ...mapActions("question", {
      getAllQuestions: "fetchAllQuestions",
      startGlobalDragging: "startDragging",
      endGlobalDragging: "endDragging",
    }),
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    addSection() {
      this.selectedItem = null;
      this.sectionDialog = true;
    },
    onEditSection(item) {
      this.selectedItem = item;
      this.sectionDialog = true;
    },
    onCloseSectionDialog() {
      this.sectionDialog = false;
    },
    onToggleExpand(item) {
      this.sections.map((s) => {
        if (s._id === item._id) {
          if (this.$refs[s._id][0].expanded) {
            this.$refs[s._id][0].onCollapse();
          } else {
            this.$refs[s._id][0].onExpand();
          }
        } else {
          this.$refs[s._id][0].onCollapse();
        }
      });
    },
    startDragging() {
      console.log("start dragging");
      this.dragging = true;
      this.startGlobalDragging();
    },
    endDragging() {
      this.dragging = false;
      this.endGlobalDragging();
    },
  },
  computed: {
    ...mapState("question", {
      sections: "sections",
      globalDragging: "dragging",
    }),
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  mounted() {
    this.getAllQuestions()
      .then(() => {
        this.loading = false;
        this.selected = 0;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.btn-section {
  height: 80px;
  border-style: dashed;
  border-color: lightgray;
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
