<template>
  <v-dialog v-model="textInputDialog" persistent max-width="600px">
    <v-card>
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline"
          >{{ item._id == -1 ? "Add" : "Edit" }} Form Element</span
        >
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <v-container>
          <v-row>
            <v-icon color="blue">mdi-checkbox-marked</v-icon>
            <div style="color:blue; padding-left: 10px;">Checkbox Input:</div>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="item.label"
                label="Label"
                type="name"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="item.key" label="Key" type="name" disabled>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.short_label"
                label="Short label"
                type="name"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.placeholder"
                label="Placeholder"
                type="name"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="4"
              style="display:flex; flex-direction: row; align-items: center;"
            >
              <div>Required</div>
              <v-switch v-model="item.required" class="ml-5"></v-switch>
            </v-col>
            <v-col
              cols="4"
              style="display:flex; flex-direction: row; align-items: center;"
            >
              <div>Conditional</div>
              <v-switch v-model="item.conditional" class="ml-5"></v-switch>
            </v-col>
            <v-col
              cols="4"
              style="display:flex; flex-direction: row; align-items: center;"
            >
              <div>Is Medical</div>
              <v-switch v-model="item.medical" class="ml-5"></v-switch>
            </v-col>
            <v-col cols="6" v-if="item.conditional">
              <v-select
                v-model="item.condition_id_item"
                :items="conditions"
                label="When Field:"
              >
              </v-select>
            </v-col>
            <v-col cols="6" v-if="item.conditional">
              <v-select
                v-model="item.condition_value"
                :items="condition_values"
                label="Is:"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="item.col"
                label="Column"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="item.order"
                label="Order"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="item.intake"
                label="Init Intake Index"
                @input="onChange"
                required
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-alert dense type="error" v-if="error" class="mx-4">
        {{ error }}
      </v-alert>
      <v-card-actions>
        <tool-tip-button
          icon="mdi-arrow-left"
          :clicked="onBack"
          tooltips="Select another element"
        />
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text :loading="loading" @click="onSave">
          {{ item._id == -1 ? "Add" : "Save" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ToolTipButton from "../core/ToolTipButton.vue";

export default {
  components: { ToolTipButton },
  props: {
    textInputDialog: {
      type: Boolean,
      default: false,
    },
    onCloseTextInputDialog: {
      type: Function,
    },
    onBack: Function,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      statusOptions: ["Yes", "No", "Urgent Help", "No response"],
      error: null,
      loading: false,
      item: {
        label: "",
        key: "",
      },
      conditions: [],
      condition_values: ["Is on", "Is off"],
    };
  },
  methods: {
    ...mapActions("question", {
      addQuestion: "addQuestion",
      editQuestion: "editQuestion",
    }),

    onSave() {
      var params = { ...this.item };
      params.form_field = "Checkbox Input";
      if (
        params.conditional &&
        (params.condition_value == null || params.condition_id_item == null)
      ) {
        this.error = "Please fill all the conditional information.";
        return;
      }
      this.loading = true;
      if (params.conditional) {
        params.condition_id = this.allQuestions.filter(
          (q) => q.label === params.condition_id_item
        )[0]._id;
      } else {
        delete params.condition_id;
        delete params.condition_value;
      }
      if (this.item._id == -1) {
        this.addQuestion(params)
          .then(() => {
            this.loading = false;
            this.error = null;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.error = error.response.data.message;
          });
      } else {
        this.editQuestion(params)
          .then(() => {
            this.loading = false;
            this.error = null;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.error = error.response.data.message;
          });
      }
    },
    onClose() {
      this.onCloseTextInputDialog();
    },
    onChange() {
      this.error = null;
      this.item.key = this.item.label.replace(/[^A-Z0-9]+/gi, "_");
      this.item.key = this.item.key.toLowerCase();
    },
  },
  computed: {
    ...mapGetters({
      question: "question/getSelectedQuestion",
      allQuestions: "question/getAllQuestions",
    }),
  },
  watch: {
    question(newValue) {
      if (newValue) {
        this.item = newValue;
      } else {
        this.item = { _id: -1 };
      }
    },
    allQuestions(newValue) {
      this.conditions = newValue
        .filter(
          (q) =>
            q.parent == this.question.parent && q.form_field === "Toggle Input"
        )
        .map((q) => q.label);
    },
  },
  mounted() {
    this.item = this.question;
    this.conditions = this.allQuestions
      .filter(
        (q) =>
          q.parent == this.question.parent && q.form_field === "Toggle Input"
      )
      .map((q) => q.label);
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
